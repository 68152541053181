import * as React from "react";
import "../styles/global.css";
import Layout from "../layout/layout";
import { graphql } from "gatsby";
import SmallCard from "../components/smallcard";
import AuthorCard from "../components/authorcard";

export const query = graphql`
  query ($slug: String!, $author: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        category
      }
      timeToRead,
      html,
      fields {
        slug
      }
    }
    allMarkdownRemark(filter: {frontmatter: {author: {eq: $author}}, fields: {slug: {ne: $slug}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            author
            date(formatString: "MMMM DD, YYYY")
            title
          }
          timeToRead
        }
      }
    }
  }
`

const Post = (props) => {

  const relatedPosts = props.data.allMarkdownRemark.edges.map( (edge) => {
    return(
      <SmallCard
        title={edge.node.frontmatter.title}
        author={edge.node.frontmatter.author}
        date={edge.node.frontmatter.date}
        slug={edge.node.fields.slug}
        timeToRead={edge.node.timeToRead}>
      </SmallCard>
    )
  })

  return(
    <Layout>
      <div className="pb-8 pt-6 animate-fadeIn">
        <h1 className="text-5xl my-4">{props.data.markdownRemark.frontmatter.title}</h1>
        <span className="text-base">By: {props.data.markdownRemark.frontmatter.author} * </span>
        <span>Published in: {props.data.markdownRemark.frontmatter.category} * </span>
        <span>{props.data.markdownRemark.frontmatter.date} * </span>
        <span>Time to read {props.data.markdownRemark.timeToRead}</span>
      </div>
      <div className="max-w-6xl mx-auto grid grid-cols-6 gap-8 animate-fadeIn">
        <div className="bg-white shadow rounded-xl my-8 col-span-4 text-lg">
          {props.data.markdownRemark.html}
        </div>
        <div className="my-8 col-span-2 flex flex-col gap-8">
          <AuthorCard></AuthorCard>
          <div className="rounded-xl flex-grow">
            <div className="text-xl text-center mb-4">
              <h1>Related Posts</h1>
            </div>
            {relatedPosts}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post;