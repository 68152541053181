import * as React from "react";
import "../styles/global.css";

const AuthorCard = () => {
  return(
    <div className="bg-white rounded-xl shadow flex-grow-0">
      <div>
        <div className="h-48 m-2 text-center border">Picture Placeholder</div>
      </div>
      <div>
        <div className="m-2">
          <h1 className="text-center">Brinzoi Daniel</h1>
          <h3 className="text-center">Salesforce Developer</h3>
        </div>
      </div>
      <div className="flex justify-center">
        <a href="https://www.github.com" rel="noreferrer" target="_blank">
          <div className="m-2 p-2 w-12 h-12 flex rounded-full shadow bg-gray-50 hover:bg-gray-100 hover-move-up">
            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>GitHub icon</title><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>
          </div>
        </a>
        <a href="https://www.linkedin.com/in/danielbrinzoi" rel="noreferrer" target="_blank">
          <div className="m-2 p-2 flex w-12 h-12 rounded-full shadow bg-gray-50  hover:bg-gray-100 hover-move-up">
            <svg className="m-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><g fill="none"><path d="M0 18.338C0 8.216 8.474 0 18.92 0h218.16C247.53 0 256 8.216 256 18.338v219.327C256 247.79 247.53 256 237.08 256H18.92C8.475 256 0 247.791 0 237.668V18.335z" fill="#069"/><path d="M77.796 214.238V98.986H39.488v115.252H77.8zM58.65 83.253c13.356 0 21.671-8.85 21.671-19.91-.25-11.312-8.315-19.915-21.417-19.915-13.111 0-21.674 8.603-21.674 19.914 0 11.06 8.312 19.91 21.169 19.91h.248zM99 214.238h38.305v-64.355c0-3.44.25-6.889 1.262-9.346 2.768-6.885 9.071-14.012 19.656-14.012 13.858 0 19.405 10.568 19.405 26.063v61.65h38.304v-66.082c0-35.399-18.896-51.872-44.099-51.872-20.663 0-29.738 11.549-34.78 19.415h.255V98.99H99.002c.5 10.812-.003 115.252-.003 115.252z" fill="#fff"/></g></svg>
          </div>
        </a>
        <a href="https://trailblazer.me/id/danielbrinzoi" rel="noreferrer" target="_blank">
          <div className="m-2 p-2 w-12 h-12 flex rounded-full shadow bg-gray-50 hover:bg-gray-100 hover-move-up">
            <svg viewBox="0 0 23.94 9.43" xmlns="http://www.w3.org/2000/svg"><path d="m23.43 5.87a.93.93 0 0 0 -.54.39 3 3 0 0 1 -2.39 1.27 1.62 1.62 0 0 1 -1.62-1 14.08 14.08 0 0 0 1.76-1.86 3.74 3.74 0 0 0 1.1-2.54 2 2 0 0 0 -1.87-2.13h-.13c-2.84.07-4 2.74-3.94 5.3a4.65 4.65 0 0 0 .2 1.17c-.59.68-1 1-1.25 1-.95 0-.66-1.91-.59-2.91s.34-3.15.12-3.44a3.37 3.37 0 0 0 -1.7-.92 4.3 4.3 0 0 0 -2.18 1.73 2.25 2.25 0 0 0 -.09-.73 3.35 3.35 0 0 0 -1.79-1 6.34 6.34 0 0 0 -2.73 2.4c0-.49.24-2 0-2a4.83 4.83 0 0 0 -2.23.29 13.42 13.42 0 0 0 -.12 2.83c-.1 1.95-.41 4.16.24 5.35a2.28 2.28 0 0 0 1.73-.27 18.25 18.25 0 0 1 .69-3.88 4.62 4.62 0 0 1 1.57-2.08c.2 0 .22.63.19.9-.08 1.3-.34 4.77 0 5.16s1.71 0 2-.08a25.6 25.6 0 0 0 .27-3.27 4.86 4.86 0 0 1 1.59-2.74c.2 0 .25.63.22.9 0 1.3-.12 4 .17 4.64a1.76 1.76 0 0 0 1.44.93 6.68 6.68 0 0 0 1.78-.22 3.1 3.1 0 0 0 1.36-1.25 4.14 4.14 0 0 0 3.36 1.62 3.8 3.8 0 0 0 3.87-2.85c.08-.48-.13-.71-.49-.71zm-3.82-4.17c.08 0 .19.09.24.27a3.63 3.63 0 0 1 -.53 1.53 7.67 7.67 0 0 1 -1.27 1.57c-.13-.87.29-3.52 1.56-3.37zm-17.23 6.24a1.19 1.19 0 1 1 -1.19-1.19 1.19 1.19 0 0 1 1.19 1.19z" fill="#00a1df"/></svg>
          </div>
        </a>
      </div>
    </div>
  )
}

export default AuthorCard;