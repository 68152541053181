import * as React from "react";
import "../styles/global.css";
import {Link} from "gatsby";

const SmallCard = (props) => {
  return(
    <Link className="" to={`/blog${props.slug}`}>
      <div className="flex bg-white rounded-xl flex-col mb-4 p-2 hover-move-up shadow cursor-pointer border-l-4 border-blue-900">
        <h1 className="text-xl mb-2">{props.title}</h1>
        <div className="flex flex-row my-2">
          <span className="flex">{props.author}</span>
          <div className="flex-grow text-right">
            <span className="">{props.date} / </span>
            <span className="">{props.timeToRead} min</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default SmallCard;